import React from "react"
import styled from "styled-components"

const Type = styled.h3`
  margin-top: 15px;
  display: ${props => props.hide && "none"};
`

export default (props) => (
  <Type hide={props.hide}>
    {props.types[props.type]} {props.exception && "(Exception)"}
  </Type>
)
