import React from "react"
import PropTypes from "prop-types"
import QuizMaster from "./quiz_master"
import Type from "../../components/quiz/type"
import Settings from "../../components/quiz/settings"
import Toggle from "../../components/quiz/toggle"

const questionLabels = {
  "eng": "English Meaning",
  "on": "On'Yomi Reading",
  "kun": "Kun'Yomi Reading"
}

class Kanji extends React.Component  {
  constructor(props) {
    super(props);
    this.kanji = {};
    this.lastkanji = {}
    this.questionType = ""
    this.answer = "";
    this.correct = 0;
    this.incorrect = 0;
    this.newKanji = this.newKanji.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.opts = {
      "eng":true,
      "on":true,
      "kun":true
    }
    this.state = {
        loaded: false,
        kanji: this.kanji,
        lastkanji: this.lastkanji,
        questionType: this.questionType,
        question: "",
        lastAnswer: "",
        status: "",
        wait: false,
        opts: this.opts,
        correct: this.correct,
        incorrect: this.incorrect,
    };
  }

  newKanji() {
    this.kanji = QuizMaster.generateItem(this.props.pageContext.kanjiData, this.lastkanji);
    this.lastkanji = this.kanji;
    let activeOpts = Object.keys(this.opts).filter(x => this.opts[x])
    if(activeOpts.length === 0) { activeOpts=Object.keys(this.opts) }
    if(activeOpts.length === 1 && this.kanji[activeOpts[0]].length === 0) {this.newKanji();}
    this.questionType = "";
    while(this.kanji[this.questionType] === undefined || this.kanji[this.questionType].length <= 0) {
      this.questionType = QuizMaster.generateItem(activeOpts);
    }
    let status = "";
    this.setState({ kanji: this.kanji, lastkanji: this.lastkanji, questionType: this.questionType, wait: false, status: status});
    this.updateQuestion()
    this.updateAnswer()
  }

  updateQuestion() {
    this.setState({ question: this.kanji.kanji });
  }

  updateAnswer() {
    this.answer = this.kanji[this.questionType]
  }

  checkAnswer(userAnswer) {
    var status = "";

    if(this.answer.map(x => x.toLowerCase()).includes(userAnswer.toLowerCase())) {
      status = "correct";
      this.correct += 1;
    } else {
      status = "incorrect";
      this.incorrect += 1;
    }
    let lastAnswer = this.answer.join(", ");
    this.setState({status: status, correct: this.correct, incorrect: this.incorrect, lastAnswer: lastAnswer, wait: true})
  }

  optToggle = e => {
    this.opts[e.target.name] = !this.opts[e.target.name]
    this.setState({ opts: this.opts });
    this.newKanji();
  }

  componentDidMount() {
    if(!this.state.loaded) {
      this.newKanji()
      this.setState({ loaded: true });
    }
  }

  render() {
    return (
      <QuizMaster title={this.props.pageContext.title}
        description={`Interactive Japanese ${this.props.pageContext.title} study tool.`}
        kanji={true}
        question={this.state.question}
        wait={this.state.wait}
        status={this.state.status}
        onSubmit={this.checkAnswer}
        onClear={this.newKanji}
        unbind={this.state.questionType === "eng"}
        noCheck={this.state.questionType === "eng"}
        preAnswer={
          <div>
            <Type type={this.state.questionType} types={questionLabels} />
          </div>
        }
        correct={this.state.correct}
        incorrect={this.state.incorrect}
        answer={this.state.lastAnswer}
        settings={
          <Settings>
            <Toggle key="eng" id="engsetting" label="English" name="eng" onToggle={this.optToggle} checked={this.state.opts["eng"]} />
            <Toggle key="on" id="onsetting" label="On'Yomi" name="on" onToggle={this.optToggle} checked={this.state.opts["on"]} />
            <Toggle key="kun" id="kunsetting" label="Kun'Yomi" name="kun" onToggle={this.optToggle} checked={this.state.opts["kun"]} />
          </Settings>
        }
      >
      </QuizMaster>
    )
  }
}

Kanji.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    kanjiData: PropTypes.array.isRequired
  }),
}

export default Kanji
